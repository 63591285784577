import styled from "styled-components";
import theme from "../../../../theme";

export const FifthSectionWrapper = styled.section`
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background: ${theme.colors.primaryColorDark};

  @media (max-width: 478px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const FifthSectionContainer = styled.section`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.whiteColor};
  padding-top: 6rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 7px;

  & > :nth-child(1) {
    position: absolute;
    left: 10%;
    top: -20%;
  }

  & > :nth-child(3) {
    position: absolute;
    right: 5%;
    top: -20%;
  }

  @media (max-width: 478px) {
    border-radius: 0;
  }
`;

export const FifthSectionLeft = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;

  & > :nth-child(3) {
    display: flex;
    gap: 2rem;
  }

  & > :nth-child(4) {
    display: flex;
    gap: 2rem;
  }

  @media (max-width: 1043px) {
    width: 100%;
  }

  @media (max-width: 619px) {
    & .r {
      display: flex;
      flex-direction: column;
    }

    & .upcoming {
      font-size: 26px;
    }
  }

  @media (max-width: 580px) {
    & .b {
      display: flex;
      flex-direction: column;
    }
  }

  /* @media (max-width: 478px){
    align-items: center;
  } */
`;

export const IconRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const FifthSectionRight = styled.section`
  height: 120%;
  width: 380px;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  background: ${theme.colors.secondaryColorLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  & > :nth-child(1) {
    position: relative;
    width: 70%;
    height: 90%;
    display: flex;
    justify-self: baseline;
    z-index: 4;
  }

  & > :nth-child(2) {
    position: absolute;
    width: 50%;
    z-index: 1;
    left: 10%;
    top: 40%;
  }

  @media (max-width: 1043px) {
    display: none;
  }
`;
