import styled from "styled-components";
import theme from "../../../../theme";

export const ForthSectionWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.primaryColorDark};
  padding: 3rem 6rem;

  @media (max-width: 1158px) {
    padding: 3rem 3rem;
  }
`;

export const ForthSectionTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;

    & > :nth-child(1) {
      padding-bottom: 1rem;
    }
  }

  @media (max-width: 1326px) {
    & > :nth-child(1) {
      width: 70%;
    }
  }

  @media (max-width: 766px) {
    & > :nth-child(1) {
      width: 100%;
    }
  }

  @media (max-width: 562px) {
    & > :nth-child(1) {
      & .boldText {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 346px) {
    & > :nth-child(1) {
      & .boldText {
        font-size: 17px;
      }
    }
  }
`;

export const ScrollController = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.whiteWithOpacity2};
  width: 45px;
  height: 45px;
  border-radius: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const ForthSectionBottom = styled.div`
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding-bottom: 60px;
`;

export const ButtonContainer = styled.div`
  margin-left: 45%;
  margin-top: 30px;
  z-index: 30px;
  display: flex;
  gap: 2rem;

  @media (max-width: 640px){
    margin-left: 35%;
  }

  @media (max-width: 480px){
    margin-left: 32%;
  }

  @media (max-width: 370px){
    margin-left: 24%;
  }
`;
