import styled from "styled-components";
import theme from "../../../theme";

export const CoursesInforCard = styled.div`
  width: 324px;
  padding: 15px;
  border: 2px solid ${theme.colors.borderColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  transition: all 300ms ease-in;
  & > :nth-child(2) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &:hover {
    box-shadow: -10px 10px 40px rgba(0, 0, 0, 0.15);
    transform: scale(0.98);
    cursor: pointer;
  }

  @media (max-width: 1134px) {
    width: 300px;
  }

  @media (max-width: 990px) {
    width: 250px;
  }
  @media (max-width: 842px) {
    width: 325px;
  }

  @media (max-width: 730px) {
    width: 300px;
  }

  @media (max-width: 710px) {
    width: 250px;
  }

  @media (max-width: 574px) {
    width: 88%;

    & > :nth-child(1) {
      width: 47px;
      height: 47px;
    }
  }
`;
