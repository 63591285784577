import styled from "styled-components";
import theme from "../../../../theme";

export const SeventhSectionWrapper = styled.section`
  width: 100%;
  display: flex;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 3rem;
  justify-content: space-between;

  @media (max-width: 948px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  @media (max-width: 520px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (max-width: 364px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const SeventhSectionRight = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: absolute;
    bottom: -5%;
    right: 5%;
    width: 60%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background: ${theme.colors.secondaryColorLight};
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: end;

    img {
      width: 90%;
      height: 90%;
    }
  }
  & > :nth-child(1) {
    width: 60%;
  }

  & > :nth-child(3) {
    position: absolute;
    z-index: 30;
    left: 10%;
    width: 43%;
  }

  @media (max-width: 948px) {
    width: 60%;
    height: 400px;
  }

  @media (max-width: 760px) {
    width: 80%;
  }

  @media (max-width: 612px) {
    width: 100%;
  }

  @media (max-width: 416px) {
    height: 300px;
  }
`;

export const SeventhSectionLeft = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 948px) {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 418px) {
    & .boldText {
      font-size: 24px;
    }
  }
`;
