import React, { useEffect, useState } from "react";
import {
  FifthSectionContainer,
  FifthSectionLeft,
  FifthSectionRight,
  FifthSectionWrapper,
  IconRow,
} from "./Fifth_Section_style";
import { CommonText } from "../../../../config/text_styles";
import theme from "../../../../theme";
import { Button } from "../../../../config/common/button_style";
import { Link, useNavigate } from "react-router-dom";
import { fetchAllEvents } from "../../../../config/fire_base";
import { Event } from "../../../../logic/response";

type FifthSectionProps = {};

const FifthSection: React.FC<FifthSectionProps> = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsData = await fetchAllEvents();
        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <FifthSectionWrapper>
      <FifthSectionContainer>
        <img src="/assets/icons/gift.svg" alt="icon" />
        <FifthSectionLeft>
          <CommonText
            fontFamily={"GilmerBold"}
            fontSize="36px"
            color={theme.colors.blackColor}
            className="upcoming"
          >
            Upcoming Events
          </CommonText>
          <CommonText
            fontFamily={"GilmerMedium"}
            fontSize="16px"
            color={theme.colors.blackColor}
          >
            {isLoading ? "Loading....." : events[0].topic}
          </CommonText>
          <div className="r">
            <IconRow>
              <img src="/assets/icons/calender.svg" alt="icon" />
              <CommonText
                color={theme.colors.tintGrey}
                fontFamily={"GilmerMedium"}
                fontSize="16px"
                // style={{
                //   textOverflow: "ellipsis",
                //   overflow: "hidden",
                //   whiteSpace: "nowrap",
                //   width: "100px"
                // }}
              >
                {isLoading ? "Loading....." : events[0].date}
              </CommonText>
            </IconRow>
            <IconRow>
              <img src="/assets/icons/Send.svg" alt="icon" />
              <CommonText
                color={theme.colors.tintGrey}
                fontFamily={"GilmerMedium"}
                fontSize="16px"
                // style={{
                //   textOverflow: "ellipsis",
                //   overflow: "hidden",
                //   whiteSpace: "nowrap",
                //   width: "100px"
                // }}
              >
                {isLoading ? "Loading....." : events[0].venue}
              </CommonText>
            </IconRow>
          </div>
          <div className="b">
            <Button
              width="194px"
              height="60px"
              onClick={() => navigate(`/event/${events[0].id}`)}
            >
              View Details
            </Button>
            <Link to="/events">
              <Button
                backgroundColor="transparent"
                width="194px"
                height="60px"
                border={"1px solid rgba(228, 228, 228, 1)"}
              >
                View All Events
              </Button>
            </Link>
          </div>
        </FifthSectionLeft>
        <FifthSectionRight>
          <img src="/assets/images/aboywithphone.png" alt="img" draggable="false"/>
          <img src="/assets/icons/Path.svg" alt="icon" draggable="false"/>
        </FifthSectionRight>
      </FifthSectionContainer>
    </FifthSectionWrapper>
  );
};
export default FifthSection;
