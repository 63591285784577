// theme.js

const theme = {
  // Colors
  colors: {
    primaryColorDark: "#02031F",
    primaryColorLight: "#0D0D30",
    primaryColorHeavy: "#01011B",
    secondaryColorDark: "#36DD8A",
    secondaryColorLight: "#32FF99",
    whiteColor: "#FFFFFF",
    blackColor: "#000000",
    blackWithOpacity: "rgba(0, 0, 0, 0.8)",
    whiteWithOpacity: "rgba(255, 255, 255, 0.85)",
    whiteWithOpacity2: "rgba(255, 255, 255, 0.56)",
    darkGreen: "",
    backgroundColor: "#F6F6FA",
    textColor: "#333",
    borderColor: "rgba(228, 228, 228, 1)",
    tintGrey: "#5B5B6D",
    dirtyGold: "#F2994A",
    grey: "#696988",
  },

  // Fonts
  fontFamily: "Arial, sans-serif",
  fontSize: {
    small: "14px",
    medium: "16px",
    large: "18px",
  },

  // Spacing
  spacing: {
    small: "8px",
    medium: "16px",
    large: "24px",
  },

  // Border radius
  borderRadius: "4px",

  // Shadows
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

  // Breakpoints
  breakpoints: {
    small: "576px",
    medium: "768px",
    large: "992px",
    extraLarge: "1200px",
  },
};

export default theme;
