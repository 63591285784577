import React from "react";
import {
  ImageContainer,
  SecondSectionBottom,
  SecondSectionLeft,
  SecondSectionRight,
  SecondSectionTitleContainer,
  SecondSectionWrapper,
} from "./second_section_style";
import { CommonText, Heading2, SpanText } from "../../../../config/text_styles";
import theme from "../../../../theme";
import OptionCard from "./widgets/option_card/Option_Card";
import { Button } from "../../../../config/common/button_style";

type SecondSectionProps = {
  onTap: any;
};

const SecondSection: React.FC<SecondSectionProps> = ({onTap}) => {
  return (
    <SecondSectionWrapper>
      <SecondSectionTitleContainer>
        <CommonText fontSize="48px" color={theme.colors.whiteColor} fontFamily={"GilmerBold"} className="boldText">
          A community based{" "}
          <SpanText color={theme.colors.secondaryColorLight} fontSize="48px" fontFamily={"GilmerBold"} className="boldText">
            learning
          </SpanText>{" "}
        </CommonText>
        <CommonText
          fontSize="16px"
          fontFamily={"GilmerMedium"}
          color={theme.colors.whiteWithOpacity}
        >
          With the aim of advancing tech by producing genuises for the future of
          tech.
        </CommonText>
      </SecondSectionTitleContainer>

      {/* bootom section */}
      <SecondSectionBottom>
      <SecondSectionLeft>
        <img src="/assets/images/group2.png" alt="img" draggable="false"/>
        <img src="/assets/images/techguys.png" alt="img" draggable="false"/>
        <ImageContainer>
          <img src="/assets/images/Mask.png" alt="img" draggable="false"/>
        </ImageContainer>
      </SecondSectionLeft>
      {/* right section */}

      <SecondSectionRight>
        <Heading2 fontSize="36px" color={theme.colors.whiteColor} className="boldText">
          Why Choose Silicio
        </Heading2>
        <OptionCard title="Get access to mentors "/>
        <OptionCard title="Community  to the community"/>
        <OptionCard title="We equip you with  pratical knowledge of top digital skills"/>
        <OptionCard title="Ocassional seminars and meetups to ensure  "/>
        <Button width="256px" height="60px" onClick={onTap}>Join next bootcamp</Button>
      </SecondSectionRight>
      </SecondSectionBottom>
    </SecondSectionWrapper>
  );
};
export default SecondSection;
