export const teamList = [
  {
    image: "/assets/images/p1.png",
    name: "Salako Oluwaseun",
    role: "Team Lead",
  },
  {
    image: "/assets/images/p3.png",
    name: "Praise Anuoluwapo",
    role: "Design Lead",
  },
  {
    image: "/assets/images/p5.png",
    name: "Adeyera Triumph",
    role: "Product Designer",
  },
  {
    image: "/assets/images/p4.png",
    name: "Olakunbi Kayode",
    role: "Frontend Engineer",
  },
  {
    image: "/assets/images/p2.png",
    name: "Grace",
    role: "Data Science",
  },
  {
    image: "/assets/images/p6.png",
    name: "Nifemi",
    role: "UIUX",
  },
];
