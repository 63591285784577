import React, { useEffect, useState } from "react";
import {
  CancelContainer,
  CustomLink,
  HeaderContainer,
  HeaderLeftSection,
  HeaderRightSection,
} from "./Header_style";
import { Button } from "../../config/common/button_style";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  const [showNav, setShowNav] = useState<boolean>(false);

  useEffect(() => {
    setShowNav(false);
  }, []);

  return (
    <HeaderContainer>
      {/* right section */}
      <HeaderLeftSection>
        <Link to="/">
          <img
            src="/assets/icons/siliciologo.svg"
            alt="Logo"
            style={{ cursor: "pointer" }}
            draggable="false"
          />
        </Link>
      </HeaderLeftSection>
      {/* right section */}
      <HeaderRightSection
        style={{
          left: showNav ? "0%" : "-100%",
          transitionTimingFunction: "ease-in",
          transitionDuration: "0.3s",
        }}
      >
        <CustomLinks to="/about" onTap={() => setShowNav((pre) => !pre)}>
          About
        </CustomLinks>
        <CustomLinks to="/courses" onTap={() => setShowNav((pre) => !pre)}>
          Courses
        </CustomLinks>
        <CustomLinks to="/events" onTap={() => setShowNav((pre) => !pre)}>
          Events
        </CustomLinks>
        <CustomLinks to="/faq" onTap={() => setShowNav((pre) => !pre)}>
          FAQ
        </CustomLinks>
        <Link to={"/contact-us"}>
          <Button width="137px" height="51px" className="btn" onClick={() => setShowNav((pre) => !pre)}>
            Contact Us
          </Button>
        </Link>
        <CancelContainer onClick={() => setShowNav((pre) => !pre)}>
          <img src="/assets/icons/cancel.svg" alt="icon" draggable="false"/>
        </CancelContainer>
      </HeaderRightSection>
      <div onClick={() => setShowNav((pre) => !pre)}>
        <img src="/assets/icons/hamburger.svg" alt="img" draggable="false"/>
      </div>
    </HeaderContainer>
  );
};
export default Header;

interface CustomLinksProps {
  to: string;
  children: React.ReactNode;
  onTap: any;
}

function CustomLinks({ to, children, onTap }: CustomLinksProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <CustomLink to={to} active={isActive} onClick={onTap}>
      {children}
    </CustomLink>
  );
}
