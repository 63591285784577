import React from "react";
import {
  SeventhSectionLeft,
  SeventhSectionRight,
  SeventhSectionWrapper,
} from "./Seventh_Section_style";
import { CommonText, SpanText } from "../../../../config/text_styles";
import { Button } from "../../../../config/common/button_style";
import theme from "../../../../theme";
import { Link } from "react-router-dom";

type SeventhSectionProps = {
  onTap: any;
};

const SeventhSection: React.FC<SeventhSectionProps> = ({ onTap }) => {
  return (
    <SeventhSectionWrapper>
      <SeventhSectionLeft>
        <CommonText
          fontFamily={"GilmerMedium"}
          fontSize="48px"
          color={theme.colors.whiteColor}
          className="boldText"
        >
          Start your tech{" "}
          <SpanText
            fontFamily={"GilmerMedium"}
            fontSize="48px"
            color={theme.colors.dirtyGold}
            className="boldText"
          >
            journey{" "}
          </SpanText>
          with us
        </CommonText>
        <CommonText
          fontFamily={"GilmerRegular"}
          fontSize="16"
          color={theme.colors.whiteWithOpacity2}
          lineHeight="26px"
        >
          At silicio, you can gain practical knowledge of any digital skill of
          your choice at your own pace and access free mentorship that will
          transform your journey.
        </CommonText>
        <Link to="/contact-us">
          <Button width="240px" height="60px">
            Contact Us{" "}
          </Button>
        </Link>
      </SeventhSectionLeft>

      {/* right section */}
      <SeventhSectionRight>
        <img src="/assets/icons/stacon.svg" alt="icon" draggable="false"/>
        <div>
          <img src="/assets/images/aboywithphone.png" alt="img" draggable="false"/>
        </div>
        <img src="/assets/icons/rect.svg" alt="icon" draggable="false"/>
      </SeventhSectionRight>
    </SeventhSectionWrapper>
  );
};
export default SeventhSection;
