import styled from "styled-components";
import theme from "../../../../theme";

export const EventDetailsWrapper = styled.section`
  background: ${theme.colors.backgroundColor};
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 3rem;
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("/assets/icons/pattern.svg");
  background-position: cover;
  @media (max-width: 921px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 809px) {
    flex-direction: column;
    gap: 3rem;
  }
`;

export const EventDetailsFirstSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  gap: 2rem;

  & .con {
    display: flex;
    align-items: center;
    gap: 2rem;

    @media (max-width: 421px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media (max-width: 1025px) {
    & .boldText {
      font-size: 30px;
    }
  }

  @media (max-width: 809px) {
    width: 100%;
  }
`;

export const EventDetailsWhatToLearn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  row-gap: 1rem;
  width: 100%;

  & .whatToLearn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  @media (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const EventDetailsCheckMarkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.secondaryColorLight};
  width: 25px;
  height: 25px;
  border-radius: 100%;
`;

export const EventDetailsIcosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// right section

export const EventDetailsSecondSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 809px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const EventSpeakerContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 20px;
  background: ${theme.colors.whiteColor};
  position: relative;
  & > :nth-child(1) {
    position: relative;
    width: 310px;
    height: 310px;
    background: ${theme.colors.grey};
    border-radius: 10px;
    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    div {
      position: absolute;
      padding: 5px;
      background: ${theme.colors.blackColor};
      border-radius: 20px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20px;
      left: 20px;
      z-index: 10;
    }
  }
  & > :nth-child(2) {
    padding: 1rem 0;
  }

  & > :nth-child(3) {
    padding-bottom: 2rem;
  }

  & > :nth-child(5) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  @media (max-width: 437px) {
    width: 100%;
    & > :nth-child(1) {
      position: relative;
      width: 100%;
      height: 310px;
    }
  }
`;

export const BottomSheet = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999999999999999;
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
`;

export const BottomSheetChild = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  overflow: auto;
  background: ${theme.colors.whiteColor};
  border-radius: 30px 30px 0 0;
`;

export const BottomSheetInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  gap: 0.3rem;
  flex-direction: column;
  & > :nth-child(2) {
    width: 380px;
    height: 200px;
    border-radius: 15px;
    padding: 0.5rem 0;
  }

  & > :nth-child(3) {
    padding-bottom: 0.5rem;
  }

  & > :nth-child(5) {
    margin-bottom: 1rem;
  }

  & > :nth-child(8) {
    margin-top: 1rem;
  }

  & > :nth-child(1) {
    margin-top: 4rem;
  }

  & .pay {
    width: 100%;
    border: 0;
    height: 45px;
    border-color: none;
    border-radius: 30px;
    transition: all 300ms ease-in;
    font-size: 16px;
    color: ${theme.colors.primaryColorLight};
    font-family: "GilmerBold", sans-serif;
    background-color: ${theme.colors.secondaryColorLight};
    &:hover {
      background: ${theme.colors.secondaryColorDark};
      cursor: pointer;
    }
  }

  @media (max-width: 430px) {
    & > :nth-child(2) {
      width: 90%;
    }

    & > :nth-child(8) {
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    width: 300px;
  }
`;

export const CloseContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.15);
  left: 5%;
  top: 5%;
  transition: all 300ms ease-in;
  &:hover {
    cursor: pointer;
    background: ${theme.colors.secondaryColorLight};
  }
`;

export const InputField = styled.input`
  width: 100%;
  outline: none;
  border: 2px solid ${theme.colors.borderColor};
  border-radius: 25px;
  padding: 0px 20px;
  background: ${theme.colors.whiteColor};
  height: 50px;
  &:focus {
    border: 2px solid ${theme.colors.primaryColorLight};
  }

  &:active {
    border: 2px solid ${theme.colors.primaryColorLight};
  }
`;
