import { initializeApp } from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { DocumentData } from "@firebase/firestore-types";
import { Event } from "../logic/response";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBVjsJyIzZGti78u9z2AvjkpEoeb9sOMy4",
  authDomain: "silicio-c7dc1.firebaseapp.com",
  projectId: "silicio-c7dc1",
  storageBucket: "silicio-c7dc1.appspot.com",
  messagingSenderId: "297538591762",
  appId: "1:297538591762:web:845f66c0f22a02c7d58ccb",
  measurementId: "G-B7H2RNGH3Z",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export const registerForEvent = async (
  eventId: string,
  userName: string,
  userEmail: string
) => {
  try {
    const eventRef = doc(db, "events", eventId);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      const registrants = eventDoc.data()?.registrants || [];

      // Check if email already exists
      const emailExists = registrants.some(
        (registrant: any) => registrant.userEmail === userEmail
      );

      if (emailExists) {
        throw new Error("Email already exists");
      }

      // If email doesn't exist, proceed with registration
      await updateDoc(eventRef, {
        registrants: arrayUnion({
          userName,
          userEmail,
        }),
      });

      console.log("User registered for the event successfully!");
    } else {
      throw new Error("Event not found!");
    }
  } catch (error) {
    throw error;
  }
};

export const registerForPaidEvent = async (
  eventId: string,
  userName: string,
  userEmail: string,
  amountPaid: number,
  paymentId: any
) => {
  try {
    const eventRef = doc(db, "events", eventId);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      // If email doesn't exist, proceed with registration

      await updateDoc(eventRef, {
        paidUser: arrayUnion({
          userName,
          userEmail,
          amountPaid,
          paymentId,
        }),
      });

      console.log("User registered for the event successfully!");
    } else {
      throw new Error("Event not found!");
    }
  } catch (error) {
    throw error;
  }
};

export const checkEmailExistence = async (email: string): Promise<boolean> => {
  try {
    const eventsRef = collection(db, "events");
    const q = query(
      eventsRef,
      where("registrants", "array-contains", { userEmail: email })
    );
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  } catch (error) {
    console.error("Error checking email existence:", error);
    throw error;
  }
};

export const fetchAllEvents = async () => {
  try {
    const eventsSnapshot = await getDocs(collection(db, "events"));
    const events = eventsSnapshot.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return events;
  } catch (error) {
    console.error("Error fetching events:", error);
    return [];
  }
};

export const getEventById = async (eventId: any) => {
  try {
    const eventDoc = await getDoc(doc(db, "events", eventId));

    if (eventDoc.exists()) {
      const eventData = eventDoc.data() as DocumentData;

      const event: Event = { id: eventDoc.id, ...eventData } as Event;

      return event;
    } else {
      throw new Error("Event not found");
    }
  } catch (error) {
    throw error;
  }
};
