import React from "react";
import { EventCardIconWidget, EventCardWrapper } from "./event_card_style";
import { CommonText } from "../../../config/text_styles";
import { Button } from "../../../config/common/button_style";
import theme from "../../../theme";

type EventCardProps = {
  image: any;
  topic: string;
  date: string;
  location: string;
  onTap: any;
};

const EventCard: React.FC<EventCardProps> = ({
  image,
  topic,
  date,
  location,
  onTap,
}) => {
  return (
    <EventCardWrapper>
      <img src={image} alt="img" draggable="false"/>

      <CommonText
        fontSize="16px"
        fontFamily={"GilmerMedium"}
        color={theme.colors.whiteColor}
      >
        {topic}
      </CommonText>
      <EventCardIconWidget>
        <img src="/assets/icons/calender.svg" alt="icon" draggable="false"/>
        <CommonText
          fontSize="14px"
          fontFamily={"GilmerMedium"}
          color={theme.colors.grey}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "85%",
          }}
        >
          {date}
        </CommonText>
      </EventCardIconWidget>
      <EventCardIconWidget>
        <img src="/assets/icons/location.svg" alt="icon" draggable="false"/>
        <CommonText
          fontSize="14px"
          fontFamily={"GilmerMedium"}
          color={theme.colors.grey}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "85%",
          }}
        >
          {location}
        </CommonText>
      </EventCardIconWidget>
      <Button onClick={onTap} width="119px" height="40px">
        View Details
      </Button>
    </EventCardWrapper>
  );
};
export default EventCard;
