import React from "react";
import {
  CoursesBottomSection,
  CoursesWrapper,
  CoursesWrapperTopSection,
} from "./CoursesPage_style";
import { CommonText, Heading2 } from "../../config/text_styles";
import theme from "../../theme";
import { coursesList } from "../../utils/courses_list";
import CoursesInfo from "./CoursesInforCard/Courses_Info";

type CoursesProps = {};

const CoursesPage: React.FC<CoursesProps> = () => {
  return (
    <CoursesWrapper>
      <CoursesWrapperTopSection>
        <div>
          <Heading2 fontSize="48px" className='boldText'>Find the skill of your choice</Heading2>
        </div>
        <div>
          <CommonText
            fontSize="16px"
            fontFamily={"GilmerMedium"}
            color={theme.colors.blackWithOpacity}
            lineHeight="25px"
            className="smallText"
          >
            At silicio, you can gain practical knowledge of any digital skill of
            your choice at your own pace and access free mentorship that will
            transform your journey.
          </CommonText>
        </div>
      </CoursesWrapperTopSection>
      {/* botton section */}

      <CoursesBottomSection>
        {coursesList.map((e) => {
          return (
            <CoursesInfo
              title={e.title}
              description={e.description}
              image={e.image}
            />
          );
        })}
      </CoursesBottomSection>
    </CoursesWrapper>
  );
};
export default CoursesPage;
