import styled from "styled-components";

export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  & > :nth-child(1) {
    width: 260px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 40%;
    }
  }

  @media (max-width: 1318px) {
    & > :nth-child(1) {
      width: 240px;
      height: 300px;
    }
  }

  @media (max-width: 1230px) {
    & > :nth-child(1) {
      width: 220px;
      height: 300px;
    }
  }

  @media (max-width: 1180px) {
    & > :nth-child(1) {
      width: 240px;
      height: 300px;
    }
  }

  @media (max-width: 922px) {
    & > :nth-child(1) {
      width: 240px;
      height: 300px;
    }
  }

  @media (max-width: 862px) {
    & > :nth-child(1) {
      width: 220px;
      height: 300px;
    }
  }

  @media (max-width: 818px) {
    & > :nth-child(1) {
      width: 200px;
      height: 300px;
    }
  }

  @media (max-width: 745px) {
    & > :nth-child(1) {
      width: 260px;
      height: 300px;
    }
  }

  @media (max-width: 654px) {
    & > :nth-child(1) {
      width: 230px;
      height: 300px;
    }
  }

  @media (max-width: 380px) {
    & > :nth-child(1) {
      width: 210px;
      height: 300px;
    }
  }
`;
