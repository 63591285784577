import React, { useState, useEffect } from "react";
import { Event } from "../../../../logic/response";
import { BottomSheetInfoContainer, InputField } from "./Event_Details_style";
import { CommonText } from "../../../../config/text_styles";
import { Button } from "../../../../config/common/button_style";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkEmailExistence,
  registerForEvent,
  registerForPaidEvent,
} from "../../../../config/fire_base";
import { PaystackButton } from "react-paystack";

type BottomSheetChildsProps = {
  event: Event;
  setModalOpened: any;
  isPay: boolean;
};

const BottomSheetChilds: React.FC<BottomSheetChildsProps> = ({
  event,
  setModalOpened,
  isPay,
}) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEmailEx, setIsEmailEx] = useState(false);

  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || "";
  const generateRandomNumber = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  useEffect(() => {
    const validateEmail = async () => {
      if (userEmail) {
        try {
          const emailExists = await checkEmailExistence(userEmail);
          setEmailError(emailExists ? "Email already exists" : "");
        } catch (error) {
          toast.error("Error checking email existence:", {
            position: "top-center",
            autoClose: 3000,
            theme: "dark",
          });
        }
      }
    };

    validateEmail();
  }, [userEmail]);

  const handleSubmit = async () => {
    if (!userName || !userEmail) {
      toast.error("Please fill out all fields", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
      return;
    }

    // Check if email error exists
    if (emailError) {
      toast.error("Email already exists", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
      return;
    }

    // Validate email using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\s*\.[^\s@]+$/;
    if (!emailRegex.test(userEmail.trim())) {
      toast.error("Invalid email format", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
      return;
    }

    setIsLoading(true);

    try {
      await registerForEvent(event.id, userName, userEmail);

      toast.success("User registered successfully!", {
        position: "bottom-center",
        autoClose: 3000,
        theme: "dark",
      });
      setModalOpened();
    } catch (error: any) {
      if (error.message === "Email already exists") {
        setEmailError("Email already exists");
      } else {
        toast.error("Error registering user", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaidEventSubmit = async () => {
    const randomGeneratedNumber = generateRandomNumber();
    if (!userName || !userEmail) {
      toast.error("Please fill out all fields", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
      return;
    }

    // Validate email using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\s*\.[^\s@]+$/;
    if (!emailRegex.test(userEmail.trim())) {
      toast.error("Invalid email format", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
      return;
    }

    setIsLoading(true);

    try {
      await registerForPaidEvent(
        event.id,
        userName,
        userEmail,
        event.amount,
        randomGeneratedNumber
      );
      toast.success("Payment successful!", {
        position: "bottom-center",
        autoClose: 3000,
        theme: "dark",
      });

      setModalOpened();
      // const templateParams = {
      //   to_email: userEmail,
      //   to_name: userName,
      //   from_name: "Silicio",
      //   message: `Thank you for registering for ${event.topic} with Silicio! 🎉 We're excited to have you on board for an enriching tech experience.`,
      //   code: randomGeneratedNumber,
      //   image: `${event.image}`,
      //   name: `${event.topic}`,
      // };

      // emailjs
      //   .send(
      //     "service_iu6jcwa",
      //     "template_h89jtlp",
      //     templateParams,
      //     "tdRXI6gLz2MvIbHpj"
      //   )
      //   .then(() => {
      //
      //   });
    } catch (error: any) {
      if (error.message === "Email already exists") {
        setEmailError("Email already exists");
      } else {
        toast.error("Error registering user", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const emailOnchanged = async (e: any) => {
    const newEmail = e.target.value;
    const emailExists = await checkEmailExistence(newEmail);
    setEmailError(emailExists ? "Email already exists" : "");
    setIsEmailEx(emailExists);
    setUserEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(newEmail.trim());
    setIsValidEmail(isValid);
  };

  return (
    <BottomSheetInfoContainer>
      <CommonText fontFamily={"GilmerMedium"} fontSize="32px">
        { isPay ? "Pay for event" : "Register for event"}
      </CommonText>
      <img src={event.image} alt="img" draggable="false" />
      <CommonText
        fontFamily={"GilmerMedium"}
        fontSize="24px"
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "86%",
          textAlign: "center",
        }}
      >
        {event.topic}
      </CommonText>
      <CommonText style={{ display: "flex", alignSelf: "flex-start" }}>
        Name
      </CommonText>
      <InputField
        placeholder="Joh"
        onChange={(e) => setUserName(e.target.value)}
        disabled={isLoading}
      />
      <CommonText style={{ display: "flex", alignSelf: "flex-start" }}>
        Email Address
      </CommonText>
      <InputField
        placeholder="Email Address"
        onChange={(e) => emailOnchanged(e)}
        disabled={isLoading}
      />
      <span style={{ color: "red" }}>{emailError}</span>
     { !isPay &&( <Button
        width="100%"
        height="60px"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Registering" : "Register for event"}
      </Button>)}

      {event.paid &&
       isPay &&
      userEmail !== "" &&
      userEmail !== null &&
      userName !== "" &&
      userName !== null &&
      !isEmailEx &&
      isValidEmail &&(
        <PaystackButton
          text="Pay Now"
          onSuccess={() => handlePaidEventSubmit()}
          email={userEmail}
          amount={event.amount * 100}
          publicKey={publicKey}
          reference={`ref_${Math.floor(Math.random() * 10e13)}`}
          className="pay"
        />
      )}
    </BottomSheetInfoContainer>
  );
};
export default BottomSheetChilds;
