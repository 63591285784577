import styled from "styled-components";
import theme from "../../../../theme";

export const ThirdSectionWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.primaryColorHeavy};
  padding: 3rem 6rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1030px) {
    flex-direction: column;
  }

  @media (max-width: 642px) {
    padding: 3rem 3rem;
  }

  @media (max-width: 386px) {
    padding: 3rem 2rem;
  }
`;

export const ThirdSectionLeft = styled.section`
  width: 50%;
  position: relative;
  margin-top: 3rem;
  & > :nth-child(1) {
    position: relative;
    width: 85%;
  }

  & > :nth-child(2) {
    position: absolute;
    width: 38%;
    height: 60%;
    background: ${theme.colors.secondaryColorLight};
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    top: 5%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    img {
      height: 90%;
      width: 90%;
    }
  }

  & > :nth-child(3) {
    position: absolute;
    width: 36%;
    height: 43%;
    border-radius: 100%;
    right: 25%;
    top: 55%;
    overflow: hidden;
    background: ${theme.colors.secondaryColorLight};
    img {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 1122px) {
    & > :nth-child(3) {
      top: 40%;
    }

    & > :nth-child(2) {
      top: 2%;
    }
  }

  @media (max-width: 1030px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    & > :nth-child(3) {
      top: 60%;
    }

    & > :nth-child(2) {
      top: 7%;
    }
  }
`;

export const ThirdSectionRight = styled.section`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 1030px) {
    width: 100%;
  }

  @media (max-width: 610px){
    & .boldText{
      font-size: 25px;
    }
  }
`;
