import React from "react";
import { OptionCardWrapper } from "./Option_Card_style";
import { FiCheck } from "react-icons/fi";
import { CommonText } from "../../../../../../config/text_styles";
import theme from "../../../../../../theme";

type OptionCardProps = {
  title: string;
};

const OptionCard: React.FC<OptionCardProps> = ({ title }) => {
  return (
    <OptionCardWrapper>
      <div>
        <FiCheck color={theme.colors.whiteColor} />
      </div>
      <div>
        <CommonText
          fontSize="14px"
          color={theme.colors.whiteColor}
          fontFamily={"GilmerRegular"}
        >
          {title}
        </CommonText>
      </div>
    </OptionCardWrapper>
  );
};
export default OptionCard;
