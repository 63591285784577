export const studentReviews = [
  {
    name: "Oladunjoye Oluwanifemi",
    image: "/assets/images/s2.jpg",
    review:
      "I enrolled for the UI/UX class and I started with no knowledge of computer or anything tech related and I must say within the space of 3 weeks, I learnt a lot. The knowledge,hardwork and experience the instructors put into tutoring us made the boot camp a success! If you're passionate about Tech and you're willing to put in the effort needed then Silicio's boot camp Is a great fit for you.",
  },
  {
    name: "Senjirin Ayomide",
    image: "/assets/images/s3.jpg",
    review:
      "Funny how I didn’t know what UI/UX was till I registered with Silicio. It’s been bliss ever since I started the training with amazing teachers, Toluwani and Scientisco, both of them took their time to ensure the students understood everything. The bootcamp was a fascinating one.",
  },
  {
    name: "Fasan Rejoice",
    image: "/assets/images/s4.jpg",
    review:
      "Silicio 1.0 was a great opportunity for me to be exposed to a tech community. I was so enlightened on the various fields of specialization and Data Science was my choice. I improved in my way of thinking unto solving problems and I have been able to work on some datasets that has helped me greatly. I love Silicio. Proudly Silicio.",
  },
  {
    name: "Oladokun Temitope",
    image: "/assets/images/s5.jpg",
    review:
      "Started out my Tech journey with Silicio and it has been a blissful one.My instructor, Stein, is a born teacher, and is all round great. And for Dunsin, his patience is second to none.      ",
  },
  {
    name: "Salaudeen Olaniyan ",
    image: "/assets/images/s1.jpg",
    review:
      "Silicio ushered me into the world of UI/UX design, giving me the necessary knowledge, tools and confidence I need as a novice in the field. The classes were very interactive and educative. All thanks to my tutors, Tolu and Scientisco, for their mentorship and the super active colleagues I had during the training.    ",
  },
];
