import styled from "styled-components";
import theme from "../../../../theme";

export const FirstSectionWrapper = styled.section`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  background: ${theme.colors.primaryColorDark};
  padding: 0 6rem;
  margin-top: 12rem;

  @media (max-width: 994px) {
    padding: 0 3rem;
  }

  @media (max-width: 922px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 8rem;
    gap: 3rem;
  }

  @media (max-width: 422px) {
    padding: 0 2rem;
  }
`;

export const FirstSectionLeft = styled.section`
  width: 35%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;

  & > :nth-child(1) {
    width: 50px;
    position: absolute;
    left: 8%;
    top: 10%;
  }

  & > :nth-child(2) {
    position: relative;
  }

  & > :nth-child(3) {
    width: 100%;
    margin-bottom: 3rem;
  }

  @media (max-width: 1214px) {
    text-align: center;
    & .boldText {
      font-size: 45px;
    }

    & > :nth-child(3) {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 922px) {
    width: 100%;
    align-items: center;
    justify-content: center;

    & > :nth-child(3) {
      width: 70%;
      margin-bottom: 1rem;
      margin-top: 1.1rem;
    }

    & .smallText {
      width: 100%;
    }

    & > :nth-child(4) {
      width: 50%;
      margin-bottom: 3rem;
      font-size: 17px;
      text-align: center;
    }
  }
  @media (max-width: 658px) {
    & > :nth-child(4) {
      width: 80%;
    }
  }

  @media (max-width: 434px) {
    & .boldText {
      font-size: 35px;
    }

    & .smallText {
      width: 90%;
      font-size: 14px;
    }
  }
`;

export const FirstSectionRight = styled.section`
  position: relative;
  width: 55%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  & > :nth-child(2) {
    position: relative;
    right: 0;
    top: -17%;
    width: 85%;
    z-index: 2;
  }
  & > :nth-child(1) {
    position: absolute;
    width: 50%;
    z-index: 20;
    right: 20%;
    top: -5%;
  }

  @media (max-width: 922px) {
    width: 100%;
    & > :nth-child(1) {
      top: 10%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
  @media (max-width: 922px) {
    margin-top: 0;
  }

  @media (max-width: 478px) {
    flex-direction: column;
  }
`;
