import styled from "styled-components";

export const EventCardWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  & > :nth-child(1) {
    width: 100%;
    height: 108px;
    border-radius: 10px;
  }

  & > :nth-child(2) {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 1185px) {
    width: 230px;
  }

  @media (max-width: 813px) {
    width: 200px;
  }

  @media (max-width: 701px) {
    width: 250px;
  }

  @media (max-width: 613px) {
    width: 230px;
  }

  @media (max-width: 553px) {
    width: 200px;
  }

  @media (max-width: 505px) {
    width: 100%;
    & > :nth-child(1) {
      width: 80%;
    }
  }

  @media (max-width: 413px) {
    width: 100%;
    & > :nth-child(1) {
      width: 100%;
      height: 120px;
    }
  }
`;

export const EventCardIconWidget = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
