import { ThemeProvider } from "styled-components";
import { AppContainer } from "./Styled_Components/AppContainer_style";
import Header from "./presentations/Header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme from "./theme";
import { GlobalStyles } from "./Styled_Components/GlobalStyle";
import Home from "./presentations/Home/Home";
import Footer from "./presentations/Footer/Footer";
import Events from "./presentations/Events/Events";
import CoursesPage from "./presentations/CoursesPage/CoursesPage";
import ScrollToTopOnNavigate from "./config/common/router";
import EventDetails from "./presentations/Events/components/Event_Details_page/Event_Details";
import { ToastContainer } from "react-toastify";
import About from "./presentations/About/About";
import Faq from "./presentations/Faqs/Faq";
import { useRef } from "react";
import ContactUs from "./presentations/contact_us/Contact_Us";

function App() {
  const sectionRef = useRef<HTMLCanvasElement | null>(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Router>
      <ScrollToTopOnNavigate />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppContainer>
          <Header />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home onTap={scrollToSection} />} />
            <Route path="/events" element={<Events />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/event/:eventId"
              element={<EventDetails onTap={scrollToSection} />}
            />
          </Routes>
          <Footer scrollRef={sectionRef} />
        </AppContainer>
      </ThemeProvider>
    </Router>
  );
}

export default App;
