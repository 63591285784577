import React from 'react';
import styled, { keyframes } from 'styled-components';


interface ShimmerProbs {
  height: any;
  width: any;
  className?:string;
}

const shimmerAnimation = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;


const ShimmerContainer = styled.div<ShimmerProbs>`
  width: ${(probs) => probs.width};
  height: ${(probs) => probs.height};
  background: linear-gradient(90deg, #505050 25%, #404040 50%, #505050 75%);
  background-size: 200% 100%;
  animation: ${shimmerAnimation} 2s infinite linear;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Shimmer: React.FC<ShimmerProbs>  = ({height, width, className}) => {
  return (
    <ShimmerContainer height={height} width={width} className={className}/>
  );
};

export default Shimmer;
