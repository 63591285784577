import React, { useEffect, useState } from "react";
import {
  BottomSheetChild,
  CloseContainer,
  EventDetailsCheckMarkContainer,
  EventDetailsFirstSection,
  EventDetailsIcosContainer,
  EventDetailsSecondSection,
  EventDetailsWhatToLearn,
  EventDetailsWrapper,
  EventSpeakerContainer,
} from "./Event_Details_style";
import { Event } from "../../../../logic/response";
import { useParams } from "react-router-dom";
import { CommonText } from "../../../../config/text_styles";
import theme from "../../../../theme";
import { Button } from "../../../../config/common/button_style";
import SeventhSection from "../../../Home/components/Seventh_Section/Seventh_Section";
import { IoMdClose } from "react-icons/io";
import { getEventById } from "../../../../config/fire_base";
import ReactLoading from "react-loading";
import BottomSheetChilds from "./Bottom_sheet_child";
import { ToastContainer } from "react-toastify";
import Sheet from "react-modal-sheet";

type EventDetailsProps = {
  onTap: any;
};

const EventDetails: React.FC<EventDetailsProps> = ({ onTap }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPay, setIsPay] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setIsPay(false);
  };

  const openModal1 = () => {
    setIsModalOpen(true);
    setIsPay(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEventById(eventId);
        setEvent(eventData);
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <ReactLoading
          type={"spinningBubbles"}
          color="white"
          height={"60px"}
          width={"60px"}
        />
      </div>
    );
  }

  if (!event) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Event not found
      </div>
    );
  }

  return (
    <div>
      {isModalOpen && (
        <Sheet isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              {" "}
              <ToastContainer />
              <BottomSheetChild>
                <BottomSheetChilds event={event} setModalOpened={closeModal} isPay={isPay}/>
                <CloseContainer onClick={closeModal}>
                  <IoMdClose />
                </CloseContainer>
              </BottomSheetChild>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      )}
      <EventDetailsWrapper>
        <EventDetailsFirstSection>
          <CommonText
            fontFamily={"GilmerBold"}
            fontSize="48px"
            className="boldText"
          >
            {event.topic}
          </CommonText>
          <CommonText
            fontFamily={"GilmerRegular"}
            fontSize="16px"
            lineHeight="26px"
          >
            {event.description}
          </CommonText>
          <CommonText
            fontFamily={"GilmerBold"}
            fontSize="24px"
            color={theme.colors.primaryColorLight}
          >
            Topics to be covered :{" "}
          </CommonText>
          <EventDetailsWhatToLearn>
            {event.whatToLearn.map((e, i) => {
              return (
                <div key={i} className="whatToLearn">
                  <EventDetailsCheckMarkContainer>
                    <img
                      src="/assets/icons/check.svg"
                      alt="icon"
                      draggable="false"
                    />
                  </EventDetailsCheckMarkContainer>
                  <CommonText
                    fontFamily={"GilmerMedium"}
                    fontSize="14px"
                    color={theme.colors.primaryColorLight}
                  >
                    {" "}
                    {e}
                  </CommonText>
                </div>
              );
            })}
          </EventDetailsWhatToLearn>
          <div className="con">
            <EventDetailsIcosContainer>
              <img
                src="\assets\icons\calender.svg"
                alt="icon"
                draggable="false"
              />
              <CommonText
                fontFamily={"GilmerMedium"}
                fontSize="14px"
                color={theme.colors.grey}
              >
                {event.date}
              </CommonText>
            </EventDetailsIcosContainer>
            <EventDetailsIcosContainer>
              <img
                src="/assets/icons/location1.svg"
                alt="icon"
                draggable="false"
              />
              <CommonText
                fontFamily={"GilmerMedium"}
                fontSize="14px"
                color={theme.colors.grey}
              >
                {event.venue}
              </CommonText>
            </EventDetailsIcosContainer>
          </div>
          <a href={event.whatsAppLink}>
            <Button width="250px" height="45px">
              Join whatsapp group
            </Button>
          </a>
        </EventDetailsFirstSection>

        {/* right section */}

        <EventDetailsSecondSection>
          <EventSpeakerContainer>
            <div>
              <img src={event.speaker.image} alt="" draggable="false" />
              <div>
                <CommonText
                  fontFamily={"GilmerRegular"}
                  fontSize="14px"
                  color={theme.colors.whiteColor}
                  lineHeight="25px"
                >
                  Speaker
                </CommonText>
              </div>
            </div>
            <CommonText
              fontFamily={"GilmerBold"}
              fontSize="24px"
              color={theme.colors.primaryColorLight}
            >
              {event.speaker.name}
            </CommonText>
            <CommonText
              fontFamily={"GilmerRegular"}
              fontSize="14px"
              color={theme.colors.grey}
              lineHeight="25px"
            >
              {event.speaker.description}
            </CommonText>
            <Button width="100%" height="50px" onClick={openModal}>
              Register for event
            </Button>
            {event.paid && (
              <Button width="100%" height="50px" onClick={openModal1}>
                Pay Now
              </Button>
            )}
          </EventSpeakerContainer>
        </EventDetailsSecondSection>
      </EventDetailsWrapper>
      <SeventhSection onTap={onTap} />
    </div>
  );
};
export default EventDetails;
