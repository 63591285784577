export const coursesList = [
  {
    image: "/assets/icons/c1.svg",
    title: "Mobile Application Development",
    description: "Learn the programming languages necessary to build mobile application by working on real life projects.",
  },
  {
    image: "/assets/icons/c2.svg",
    title: "Frontend Developement",
    description: "You will be taught all you need to know to code the user interface of web applications including HTML, CSS AND Javascript",
  },
  {
    image: "/assets/icons/c3.svg",
    title: "Backend Development",
    description: "Learn the programming languages necessary to build mobile application by working on real life projects.",
  },
  {
    image: "/assets/icons/c4.svg",
    title: "Product Design (UI/UX)",
    description: "Learn the programming languages necessary to build mobile application by working on real life projects.",
  },
  {
    image: "/assets/icons/c5.svg",
    title: "Brand Identity Design",
    description: "Learn the programming languages necessary to build mobile application by working on real life projects.",
  },
  {
    image: "/assets/icons/c6.svg",
    title: "Data Science",
    description: "Learn the programming languages necessary to build mobile application by working on real life projects.",
  },
];
