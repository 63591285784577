import React from "react";
import {
  ThirdSectionLeft,
  ThirdSectionRight,
  ThirdSectionWrapper,
} from "./Third_Section_style";
import { CommonText, Heading2 } from "../../../../config/text_styles";
import theme from "../../../../theme";
import { Button } from "../../../../config/common/button_style";
import { Link } from "react-router-dom";

type ThirdSectionProps = {
  onTap: any;
};

const ThirdSection: React.FC<ThirdSectionProps> = ({ onTap }) => {
  return (
    <ThirdSectionWrapper>
      <ThirdSectionLeft>
        <img src="/assets/images/group3.png" alt="img" draggable="false"/>
        <div>
          <img src="/assets/images/aboywithphone.png" alt="img" draggable="false"/>
        </div>
        <div>
          <img src="/assets/images/agirlwithorange.png" alt="img" draggable="false"/>
        </div>
      </ThirdSectionLeft>

      {/* right section */}
      <ThirdSectionRight>
        <CommonText
          fontSize="16px"
          color={theme.colors.secondaryColorLight}
          fontFamily={"GilmerBold"}
        >
          Development
        </CommonText>
        <Heading2
          fontSize="36px"
          color={theme.colors.whiteColor}
          className="boldText"
        >
          Have an idea or project you want to execute ?
        </Heading2>
        <CommonText
          fontSize="16px"
          color={theme.colors.whiteWithOpacity2}
          fontFamily={"GilmerMedium"}
          lineHeight="26px"
        >
          Silicio development team is team of tech experts in community. We help
          individuals, organisations and startups build successful products .
        </CommonText>
        <Link to="/contact-us">
          <Button width="188px" height="60px">
            Contact Us
          </Button>
        </Link>
      </ThirdSectionRight>
    </ThirdSectionWrapper>
  );
};
export default ThirdSection;
