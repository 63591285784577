import React, { useEffect, useState } from "react";
import { FaqWrapper } from "./Faq_style";
import Lottie from "lottie-react";

type FaqProps = {};

const Faq: React.FC<FaqProps> = () => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    import("../../Animation - 1700208189120.json")
      .then((data: any) => setAnimationData(data))
      .catch((error) => console.error("Error loading animation data:", error));
  }, []);

  if (!animationData) {
    return null;
  }

  return <FaqWrapper>
    <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{width: "80%"}}
      />
  </FaqWrapper>;
};
export default Faq;
