import React from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import {
  ButtonContainers,
  SixthSectionBottom,
  SixthSectionTop,
  SixthSectionWrapper,
} from "./Sixth_SEction_style";
import { studentReviews } from "../../../../utils/student_reviews_list";
import Student from "./widget/Student";
import { CommonText, SpanText } from "../../../../config/text_styles";
import theme from "../../../../theme";
import { ScrollController } from "../Forth_Section/Forth_Section_style";

type SixthSectionProps = {};

const SixthSection: React.FC<SixthSectionProps> = () => {
  return (
    <SixthSectionWrapper>
      <SixthSectionTop>
        <CommonText
          fontFamily={"GilmerBold"}
          color={theme.colors.whiteColor}
          fontSize="36px"
          className="boldText"
        >
          Hear from our{" "}
          <SpanText
            fontFamily={"GilmerBold"}
            color={theme.colors.secondaryColorLight}
            fontSize="36px"
            className="boldText"
          >
            students
          </SpanText>
        </CommonText>
      </SixthSectionTop>
      <SixthSectionBottom>
        <Swiper>
          <SliderButtons />
          {studentReviews.map((e, i) => {
            return (
              <SwiperSlide key={i}>
                <Student image={e.image} name={e.name} review={e.review} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SixthSectionBottom>
    </SixthSectionWrapper>
  );
};
export default SixthSection;

const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <ButtonContainers>
      <ScrollController onClick={() => swiper.slidePrev()}>
        <BsArrowLeft color={theme.colors.whiteColor} />
      </ScrollController>
      <ScrollController onClick={() => swiper.slideNext()}>
        <BsArrowRight color={theme.colors.whiteColor} />
      </ScrollController>
    </ButtonContainers>
  );
};
