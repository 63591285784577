import React from "react";
import {
  ContactUsContainer,
  ContactUsEmailInput,
  ContactUsTextArea,
  ContactUsWrapper,
} from "./Contact_Us_style";
import { CommonText } from "../../config/text_styles";
import theme from "../../theme";
import { Button } from "../../config/common/button_style";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type ContactUsProps = {};

const ContactUs: React.FC<ContactUsProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    const schema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      message: Yup.string().required("Message is required"),
    });

    schema
      .validate({ name, email, message }, { abortEarly: false })
      .then(() => {
        const templateParams = {
          from_name: name,
          from_email: email,
          message: message,
        };

        emailjs
          .send(
            "service_iu6jcwa",
            "template_h89jtlp",
            templateParams,
            "tdRXI6gLz2MvIbHpj"
          )
          .then(() => {
            toast.success("Feedback sent we'll get in touch shortly.", {
              position: "bottom-center",
              autoClose: 3000,
              theme: "dark",
            });
            setName("");
            setEmail("");
            setMessage("");
            setIsLoading(false);
          })
          .catch((error) => {
            setErrorMessage(
              "An error occurred while submitting the form. Please try again later."
            );
            toast.error("An error occurred. Try again", {
              position: "bottom-center",
              autoClose: 3000,
              theme: "dark",
            });
            setIsLoading(false);
          });
      })
      .catch((error: any) => {
        // Invalid form data
        const errors = error.inner.reduce((acc: any, err: any) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        setErrorMessage(errors);
        setIsLoading(false);
      });
  };

  const handleName = (e: any) => {
    setName(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleMessage = (e: any) => {
    setMessage(e.target.value);
  };
  return (
    <ContactUsWrapper>
      <ContactUsContainer onSubmit={handleSubmit}>
        <CommonText
          fontSize="38px"
          fontFamily={"GilmerBold"}
          color={theme.colors.whiteColor}
        >
          Get In Touch
        </CommonText>
        <CommonText
          fontSize="16px"
          fontFamily={"GilmerRegular"}
          color={theme.colors.whiteWithOpacity2}
          lineHeight="25px"
        >
          Thank you for your interest in Silicio! For any inquiries, partnership
          opportunities, or general questions, feel free to reach out to us. Our
          dedicated team is here to assist you.
        </CommonText>
        <ContactUsEmailInput
          placeholder="Name*"
          type="text"
          id="name"
          onChange={handleName}
          value={name}
          disabled={isLoading}
        />
        {errorMessage.name && <div className="error">{errorMessage.name}</div>}
        <ContactUsEmailInput
          placeholder="Email Address*"
          type="email"
          id="email"
          onChange={handleEmail}
          value={email}
          disabled={isLoading}
        />
        {errorMessage.email && (
          <div className="error">{errorMessage.email}</div>
        )}
        <ContactUsTextArea
          placeholder="What can we help you with?*"
          id="message"
          value={message}
          onChange={handleMessage}
          disabled={isLoading}
        />
        {errorMessage.message && <div className="error">{errorMessage.message}</div>}
        <Button width="50%" height="55px" type="submit">
         { isLoading ? "Sending" : "Send"}
        </Button>
      </ContactUsContainer>
    </ContactUsWrapper>
  );
};
export default ContactUs;
