import styled from "styled-components";

export const StudentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 747px) {
    flex-direction: column-reverse;
    gap: 3rem;
  }
`;

export const StudentLeft = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  z-index: 1;

  div {
    max-width: 300px;
    height: 340px;
    position: relative;

    & > :nth-child(1) {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }

    & > :nth-child(2) {
      position: absolute;
      width: 60%;
      bottom: -15%;
      left: -15%;
    }
    & > :nth-child(3) {
      position: absolute;
      bottom: -15%;
      right: -20%;
      width: 60%;
    }
  }

  @media (max-width: 747px) {
    width: 100%;
    div {
      max-width: 330px;
      height: 370px;
    }
  }

  @media (max-width: 467px) {
    div {
      max-width: 300px;
      height: 330px;
      & > :nth-child(2) {
        position: absolute;
        width: 40%;
        bottom: -15%;
        left: -15%;
      }
      & > :nth-child(3) {
        position: absolute;
        bottom: -15%;
        right: -20%;
        width: 40%;
      }
    }
  }

  @media (max-width: 395px) {
    div {
      max-width: 250px;
      height: 290px;
      & > :nth-child(2) {
        position: absolute;
        width: 40%;
        bottom: -15%;
        left: -15%;
      }
      & > :nth-child(3) {
        position: absolute;
        bottom: -15%;
        right: -20%;
        width: 40%;
      }
    }
  }

  @media (max-width: 345px) {
    div {
      max-width: 230px;
      height: 250px;
      & > :nth-child(2) {
        position: absolute;
        width: 40%;
        bottom: -15%;
        left: -15%;
      }
      & > :nth-child(3) {
        position: absolute;
        bottom: -15%;
        right: -20%;
        width: 40%;
      }
    }
  }
`;

export const StudentRight = styled.div`
  position: relative;
  z-index: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 4rem;

  & > :nth-child(1) {
    width: 40px;
    height: 40px;
  }
  & > :nth-child(2) {
    padding-top: 3rem;
    padding-bottom: 0.3rem;
    width: 90%;
  }

  & > :nth-child(3) {
    padding-top: 1rem;
  }
  @media (max-width: 747px) {
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 445px){
    & .smallText{
      font-size: 16px;
    }
  }
`;
