import React, { useEffect, useState } from "react";
import {
  EventBottomSection,
  EventTopSection,
  EventWrapper,
  LoadingContainer,
  ShimmerContainer,
} from "./Events_styles";
import { CommonText } from "../../config/text_styles";
import theme from "../../theme";
import EventCard from "./widgets/EventCard";
import { useNavigate } from "react-router-dom";
import { fetchAllEvents } from "../../config/fire_base";
import { Event } from "../../logic/response";
import Shimmer from "../../config/common/shimmer";

type EventsProps = {};

const Events: React.FC<EventsProps> = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsData = await fetchAllEvents();
        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <CommonText
          fontFamily={"GilmerMedium"}
          fontSize="36px"
          color={theme.colors.whiteColor}
          className="boldText"
        >
          Upcoming Events
        </CommonText>
        <ShimmerContainer>
          <Shimmer height={"200px"} width={"300px"} className="con" />
          <Shimmer height={"200px"} width={"300px"} className="con" />
          <Shimmer height={"200px"} width={"300px"} className="con" />
          <Shimmer height={"200px"} width={"300px"} className="con" />
        </ShimmerContainer>
      </LoadingContainer>
    );
  }

  return (
    <EventWrapper>
      <EventTopSection>
        <CommonText
          fontFamily={"GilmerMedium"}
          fontSize="36px"
          color={theme.colors.whiteColor}
          className="c"
        >
          Upcoming Events
        </CommonText>
      </EventTopSection>
      <EventBottomSection>
        {events.map((e, i) => {
          return (
            <EventCard
              key={i}
              image={e.image}
              location={e.venue}
              topic={e.topic}
              onTap={() => {
                navigate(`/event/${e.id}`);
              }}
              date={e.date}
            />
          );
        })}
      </EventBottomSection>
    </EventWrapper>
  );
};
export default Events;
