import styled from "styled-components";
import theme from "../../../../theme";

export const SecondSectionWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.primaryColorLight};
  padding: 0 6rem;

  @media (max-width: 814px) {
    padding: 0 3rem;
  }
`;

export const SecondSectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  gap: 0.5rem;

  @media (max-width: 882px) {
    text-align: center;
    gap: 1.5rem;
    & .boldText {
      font-size: 30px;
    }
  }
`;

export const SecondSectionBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  gap: 0.5rem;

  @media (max-width: 1054px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SecondSectionLeft = styled.div`
  width: 50%;
  margin-top: 3rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  & > :nth-child(1) {
    width: 90%;
    position: relative;
    z-index: 1;
  }

  & > :nth-child(2) {
    position: absolute;
    z-index: 3;
    width: 44%;
    right: 7%;
    bottom: 32%;
  }

  & > :nth-child(3) {
    position: absolute;
    z-index: 4;
    left: 1%;
    bottom: 20%;
  }

  @media (max-width: 1054px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  height: 40%;
  width: 37%;
  background: ${theme.colors.secondaryColorLight};
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const SecondSectionRight = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  & > :nth-child(1) {
    margin-bottom: 2rem;
  }
  & > :nth-child(6) {
    margin-top: 3rem;
  }

  @media (max-width: 1054px) {
    width: 100%;
    padding-bottom: 4rem;
    & .boldText {
      text-align: center;
    }
  }

  @media (max-width: 510px) {
    & .boldText {
      font-size: 25px;
      text-align: center;
    }
  }
`;
