import styled from "styled-components";
import theme from "../../theme";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.primaryColorLight};
  margin-top: auto;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3rem 6rem;

  & > :nth-child(1) {
    width: 30%;
    gap: 2rem;
  }

  & > :nth-child(2) {
    gap: 2rem;
  }

  & > :nth-child(3) {
    gap: 2rem;
  }

  @media (max-width: 1180px) {
    flex-direction: column;
    gap: 3rem;

    & > :nth-child(1) {
      width: 100%;
      gap: 2rem;
    }
  }

  @media (max-width: 592px) {
    padding: 3rem 2rem;

    & > :nth-child(1) {
      img {
        width: 100px;
      }
    }
  }
`;

export const FooterBottomSection = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.whiteWithOpacity2};
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

export const InforContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TextfieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${theme.colors.whiteWithOpacity2};
  border-radius: 30px;
  height: 65px;

  @media (max-width: 488px) {
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
    border: 0;

    & > :nth-child(1) {
      width: 100%;
      border: 2px solid ${theme.colors.whiteWithOpacity2};
    }

    & > :nth-child(2) {
      width: 100%;
    }
  }
`;

export const InputField = styled.input`
  border: 0;
  background-color: transparent;
  height: 60px;
  border-radius: 30px;
  outline: none;
  padding: 0 1rem;
  color: ${theme.colors.whiteColor};

  &:focus {
    border: 0;
  }
`;

export const IconLinksContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export const IconLink = styled.a`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.secondaryColorLight};

  &:hover {
    cursor: pointer;
  }
`;

export const CustomLink = styled(Link)`
  font-size: 1rem;
  font-family: "GilmerBold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${theme.colors.whiteWithOpacity2};
  cursor: pointer;

  &.active {
    border: 4px solid ${theme.colors.primaryColorDark};
    color: ${theme.colors.primaryColorDark};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
  }
`;
