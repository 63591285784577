import React, { useEffect, useState } from "react";
import { AboutWrapper } from "./About_style";
import Lottie from "lottie-react";

type AboutProps = {};

const About: React.FC<AboutProps> = () => {
    const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    import("../../Animation - 1700208189120.json")
      .then((data: any) => setAnimationData(data))
      .catch((error) => console.error("Error loading animation data:", error));
  }, []);

  if (!animationData) {
    return null;
  }
  return (
    <AboutWrapper>
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{width: "80%"}}
      />
    </AboutWrapper>
  );
};
export default About;
