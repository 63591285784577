import React, { useRef } from "react";
import { HomeWrapper } from "./Home_style";
import FirstSection from "./components/First_Section/First_Section";
import SecondSection from "./components/Second_Section/Second_Section";
import Courses from "../Courses/Courses";
import ThirdSection from "./components/Third_Section/Third_Section";
import ForthSection from "./components/Forth_Section/Forth_Section";
import FifthSection from "./components/fifth_section/Fifth_Section";
import SixthSection from "./components/Sixth_Section/Sixth_Section";
import SeventhSection from "./components/Seventh_Section/Seventh_Section";

type HomeProps = {
  onTap: any;
};

const Home: React.FC<HomeProps> = ({onTap}) => {
  const sectionRef = useRef<HTMLCanvasElement | null>(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <HomeWrapper>
      <FirstSection onTap={scrollToSection} onTap2={onTap}/>
      <SecondSection onTap={scrollToSection}/>
      <Courses scrollRef={sectionRef}/>
      <ThirdSection onTap={onTap}/>
      <ForthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection onTap={onTap}/>
    </HomeWrapper>
  );
};
export default Home;
