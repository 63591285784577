import styled from "styled-components";
import theme from "../../theme";

export const CoursesWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.whiteColor};
  padding: 0 6rem;

  @media (max-width: 1050px) {
    padding: 0 3rem;
  }

  @media (max-width: 442px) {
    padding: 0 2rem;
  }
`;

export const CoursesWrapperTopSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 8rem;

  & > :nth-child(1) {
    width: 40%;
  }

  & > :nth-child(2) {
    width: 40%;
  }

  @media (max-width: 930px) {
    text-align: center;
    flex-direction: column;
    gap: 1rem;
    & > :nth-child(1) {
      width: 100%;
    }

    & > :nth-child(2) {
      width: 100%;
    }
  }

  @media (max-width: 742px) {
    & > :nth-child(1) {
      & .boldText {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    & > :nth-child(1) {
      & .boldText {
        font-size: 20px;
      }
    }

    & > :nth-child(2) {
      & .smallText {
        font-size: 13px;
      }
    }
  }
`;

export const CoursesBottomSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  row-gap: 3rem;
  margin-top: 4rem;
  padding-bottom: 6rem;

  @media (max-width: 842px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 574px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
