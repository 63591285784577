import React from "react";
import {
  CustomLink,
  FooterBottomSection,
  FooterContainer,
  FooterWrapper,
  IconLink,
  IconLinksContainer,
  InforContainer,
  InputField,
  TextfieldContainer,
} from "./Footer_style";
import { CommonText } from "../../config/text_styles";
import theme from "../../theme";
import { Button } from "../../config/common/button_style";
import { AiOutlineMessage } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaLinkedinIn } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type FooterProps = {
  scrollRef: any;
};

const Footer: React.FC<FooterProps> = ({ scrollRef }) => {


  const clearField = () =>{
    toast.success("Comming soon", {
      position: "bottom-center",
      autoClose: 3000,
      theme: "dark",
    });
  }
  return (
    <FooterWrapper ref={scrollRef}>
      <FooterContainer>
        <InforContainer>
          <img src="/assets/icons/siliciologo.svg" alt="icon" width={200} />
          <CommonText
            fontSize="16px"
            fontFamily={"GilmerMedium"}
            color={theme.colors.whiteWithOpacity2}
            lineHeight="25px"
          >
            Silicio is a tech community with the aim of helping people learn
            digital skills, develop their creativity, build tech-inspired
            relationships, and producing genuises for the future of tech.
          </CommonText>
        </InforContainer>
        <InforContainer>
          <CustomLinks to="/courses">programmes</CustomLinks>
          <CustomLinks to="/faq">faq</CustomLinks>
          <CustomLinks to="/">mentors</CustomLinks>
          <CustomLinks to="/about">about us</CustomLinks>
          <Link to="/courses">
            <Button width="169px" height="60px">
              Enroll
            </Button>
          </Link>
        </InforContainer>
        <InforContainer>
          <CommonText
            fontSize="16px"
            fontFamily={"GilmerBold"}
            color={theme.colors.whiteColor}
          >
            Subscribe to our newsletter
          </CommonText>
          <TextfieldContainer>
            <InputField
              placeholder="Enter your Email"
            />
            <Button width="219px" height="60px" onClick={clearField}>
              Send message
            </Button>
          </TextfieldContainer>
          <IconLinksContainer>
            <IconLink href="https://twitter.com/_silicio">
              <img src="/assets/icons/twitter.svg" alt="" />
            </IconLink>
            <IconLink href="https://wa.me/2348149437093">
              <AiOutlineMessage color={theme.colors.whiteColor} />
            </IconLink>
            <IconLink href="https://wa.me/2348149437093">
              <BsWhatsapp color={theme.colors.whiteColor} />
            </IconLink>
            <IconLink href="https://www.linkedin.com/company/silicio">
              <FaLinkedinIn color={theme.colors.whiteColor} />
            </IconLink>
          </IconLinksContainer>
        </InforContainer>
      </FooterContainer>
      <FooterBottomSection>
        <CommonText
          fontSize="16px"
          fontFamily={"GilmerMedium"}
          color={theme.colors.whiteWithOpacity2}
        >
          Copyright @ 2022 silicio bootcamp
        </CommonText>
      </FooterBottomSection>
    </FooterWrapper>
  );
};
export default Footer;
interface CustomLinksProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

function CustomLinks({ to, children, className }: CustomLinksProps) {
  return (
    <CustomLink to={to} className={className}>
      {children}
    </CustomLink>
  );
}
