import { createGlobalStyle } from "styled-components";
import theme from "../theme";
export const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'GilmerBold';
  src: url('/fonts/Gilmer Bold.otf')
}

@font-face {
  font-family: 'GilmerMedium';
  src: url('/fonts/Gilmer Medium.otf')
}

@font-face {
  font-family: 'GilmerRegular';
  src: url('/fonts/Gilmer Regular.otf')
}

@font-face {
  font-family: 'GilmerLight';
  src: url('/fonts/Gilmer Light.otf')
}
  body {
    font-family: 'GilmerRegular', sans-serif;
    width: 100%;
    padding: 0;
    margin: 0;
    background: ${theme.colors.primaryColorDark};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;
