import styled from "styled-components";
import theme from "../../../../../../theme";

export const OptionCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  & > :nth-child(1) {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.secondaryColorLight};
    border-radius: 100%;
  }
`;
