import React from "react";
import { StudentContainer, StudentLeft, StudentRight } from "./Student_style";
import { CommonText } from "../../../../../config/text_styles";
import theme from "../../../../../theme";

type StudentProps = {
  image: string;
  name: string;
  review: string;
};

const Student: React.FC<StudentProps> = ({ image, name, review }) => {
  return (
    <StudentContainer>
      <StudentLeft>
        <div>
          <img src={image} alt="" draggable="false"/>
          <img src="/assets/images/Path.png" alt="" draggable="false"/>
          <img src="/assets/images/Star.png" alt="" draggable="false"/>
        </div>
      </StudentLeft>
      <StudentRight>
        <img src="/assets/icons/comar.svg" alt="" />
        <CommonText
          color={theme.colors.whiteWithOpacity2}
          fontSize="18px"
          fontFamily={"GimerMedium"}
          lineHeight="26px"
          className="smallText"
        >
          {review}
        </CommonText>
        <CommonText
          color={theme.colors.whiteColor}
          fontFamily={"GimerMedium"}
          lineHeight="16px"
        >
          {name}
        </CommonText>
      </StudentRight>
    </StudentContainer>
  );
};
export default Student;
