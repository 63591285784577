import styled from "styled-components";
import theme from "../../../../theme";

export const SixthSectionWrapper = styled.section`
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: ${theme.colors.primaryColorLight};
  @media (max-width: 915px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (max-width: 427px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
export const SixthSectionTop = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 3rem;

  @media (max-width: 515px) {
    & .boldText {
      font-size: 18px;
    }
  }
`;

export const ButtonContainers = styled.div`
  margin-left: 48%;
  z-index: 30;
  display: flex;
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 865px) {
    margin-top: 4rem;
  }

  @media (max-width: 747px) {
    margin-left: 40%;
  }

  @media (max-width: 500px) {
    margin-left: 30%;
  }
`;
export const SixthSectionBottom = styled.div`
  position: relative;
  z-index: 1;
`;
