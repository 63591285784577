import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";

interface HeaderProbs {
  active?: any;
  left?: any;
}

export const HeaderContainer = styled.section`
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 99999;
  background: ${theme.colors.primaryColorLight};
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 6rem;

  & > :nth-child(3) {
    display: none;
  }

  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);

  @media (max-width: 1018px) {
    padding: 0.7rem 3rem;
  }

  @media (max-width: 694px) {
    & > :nth-child(3) {
      display: flex;
    }
  }
`;

export const HeaderLeftSection = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 100px;
    height: 40px;
  }

  @media (max-width: 694px) {
    img {
      width: 70px;
      height: 30px;
    }
  }
`;

export const HeaderRightSection = styled.div<HeaderProbs>`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :nth-child(6) {
    display: none;
  }
  @media (max-width: 898px) {
    width: 70%;
  }

  @media (max-width: 694px) {
    position: absolute;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.primaryColorDark};
    left: ${(probs) => probs.left};
    right: 0;
    height: 100vh;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding: 7rem 3rem;
    top: 0;

    & .btn {
      background: transparent;
      padding: 0;
      color: ${theme.colors.whiteWithOpacity2};
      width: auto;
      height: auto;
    }

    & > :nth-child(6) {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      position: absolute;
      right: 5%;
      top: 5%;
    }
  }
`;

export const CustomLink = styled(Link)<HeaderProbs>`
  font-size: 1rem;
  font-weight: 400;
  font-family: "GilmerRegular", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${(props) =>
    props.active ? theme.colors.whiteColor : theme.colors.whiteWithOpacity2};
  border-bottom: ${(props) => (props.active ? "2px solid white" : "0")};
  padding-bottom: 4px;
  cursor: pointer;
`;

export const CancelContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;
