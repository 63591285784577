import React from "react";
import { TeamWrapper } from "./Team_style";
import { CommonText } from "../../../../../config/text_styles";
import theme from "../../../../../theme";

type TeamProps = {
  image: any;
  name: any;
  role: any;
};

const Team: React.FC<TeamProps> = ({ image, name, role }) => {
  return (
    <TeamWrapper>
      <div>
        <img src={image} alt="img" draggable="false"/>
      </div>
      <CommonText
        fontSize="18px"
        color={theme.colors.whiteColor}
        fontFamily={"GilmerBold"}
      >
        {name}
      </CommonText>
      <CommonText
        fontSize="14px"
        color={theme.colors.whiteWithOpacity2}
        fontFamily={"GilmerMedium"}
      >
        {role}
      </CommonText>
    </TeamWrapper>
  );
};
export default Team;
