import React from "react";
import {
  ButtonContainer,
  ForthSectionBottom,
  ForthSectionTop,
  ForthSectionWrapper,
  ScrollController,
} from "./Forth_Section_style";
import { CommonText } from "../../../../config/text_styles";
import theme from "../../../../theme";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import "swiper/css";
import { sliderSettings } from "../../../../utils/swiper_settings";
import { teamList } from "../../../../utils/team_list";
import Team from "./widget/Team";

type ForthSectionProps = {};

const ForthSection: React.FC<ForthSectionProps> = () => {
  return (
    <ForthSectionWrapper>
      <ForthSectionTop>
        <div>
          <CommonText
            fontSize="16px"
            fontFamily={"GilmerMedium"}
            color={theme.colors.secondaryColorLight}
          >
            Team
          </CommonText>
          <CommonText
            fontSize="36px"
            fontFamily={"GilmerBold"}
            color={theme.colors.whiteColor}
            className="boldText"
          >
            Meet Our Team of designers and developers
          </CommonText>
        </div>
      </ForthSectionTop>

      {/* bottom section */}
      <ForthSectionBottom>
        <Swiper {...sliderSettings}>
          <SliderButtons />
          {teamList.map((e, i) => {
            return (
              <SwiperSlide key={i}>
                <Team name={e.name} image={e.image} role={e.role} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ForthSectionBottom>
    </ForthSectionWrapper>
  );
};
export default ForthSection;

const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <ButtonContainer>
      <ScrollController onClick={() => swiper.slidePrev()}>
        <BsArrowLeft color={theme.colors.whiteColor} />
      </ScrollController>
      <ScrollController onClick={() => swiper.slideNext()}>
        <BsArrowRight color={theme.colors.whiteColor} />
      </ScrollController>
    </ButtonContainer>
  );
};
