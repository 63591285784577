import React from "react";
import { CoursesInforCard } from "./Courses_infor_style";
import { CommonText } from "../../../config/text_styles";
import theme from "../../../theme";

type CoursesInfoProps = {
  image: string;
  title: string;
  description: string;
  onClick: any;
};

const CoursesInfo: React.FC<CoursesInfoProps> = ({
  image,
  title,
  description,
  onClick
}) => {
  return (
    <CoursesInforCard onClick={onClick}>
      <img src={image} alt="icon" />
      <CommonText fontSize="16px" fontFamily={"GilmerBold"}>
        {title}
      </CommonText>
      <CommonText
        fontSize="16px"
        fontFamily={"GilmerMedium"}
        color={theme.colors.blackWithOpacity}
        lineHeight="25px"
      >
        {description}
      </CommonText>
    </CoursesInforCard>
  );
};
export default CoursesInfo;
