import styled from "styled-components";
import theme from "../../theme";
interface ButtonProbs {
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  borderColor?: any;
  border?: any;
  onBackground?: any;
  color?: any;
}

export const Button = styled.button<ButtonProbs>`
  width: ${(probs) => probs.width || "auto"};
  border: ${(probs) => probs.border || "0"};
  height: ${(probs) => probs.height || "auto"};
  border-color: ${(probs) => probs.borderColor || "none"};
  border-radius: ${(probs) => probs.borderRadius || "30px"};
  transition: all 300ms ease-in;
  font-size: 16px;
  color: ${(probs) => probs.color || theme.colors.primaryColorLight};
  font-family: "GilmerBold", sans-serif;
  background-color: ${(probs) =>
    probs.backgroundColor || theme.colors.secondaryColorLight};
&:hover {
    background: ${(probs) => probs.onBackground || theme.colors.secondaryColorDark};
    cursor: pointer;
  }
`;
