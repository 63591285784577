import styled from "styled-components";
import theme from "../../theme";

export const ContactUsWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  margin-bottom: 4rem;
`;

export const ContactUsContainer = styled.form`
  width: 40%;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-direction: column;

  & .error {
    color: red;
  }

  & > :nth-child(2) {
    width: 100%;
  }

  & > :nth-child(5) {
    width: 100%;
  }

  @media (max-width: 1150px) {
    width: 50%;
  }

  @media (max-width: 1010px) {
    width: 60%;
  }

  @media (max-width: 833px) {
    width: 70%;
  }

  @media (max-width: 713px) {
    width: 80%;
  }

  @media (max-width: 473px) {
    width: 90%;
  }
`;

export const ContactUsEmailInput = styled.input`
  outline: none;
  background: transparent;
  border: 2px solid ${theme.colors.whiteWithOpacity2};
  padding: 10px;
  width: 100%;
  height: 60px;
  border-radius: 7px;
  display: flex;
  align-self: flex-start;
  color: ${theme.colors.whiteColor};
  font-size: 16px;
  font-weight: "GilmerBold";
`;

export const ContactUsTextArea = styled.textarea`
  outline: none;
  background: transparent;
  border: 2px solid ${theme.colors.whiteWithOpacity2};
  padding: 10px;
  width: 100%;
  height: 60px;
  border-radius: 7px;
  display: flex;
  align-self: flex-start;
  color: ${theme.colors.whiteColor};
  font-size: 18px;
  font-weight: "GilmerBold";
`;
