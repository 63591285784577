import styled from "styled-components";

export const EventWrapper = styled.section`
  width: 100%;
  padding: 3rem 6rem;
  margin-top: 3rem;

  @media (max-width: 1101px) {
    padding: 3rem 3rem;
  }

  @media (max-width: 413px) {
    padding: 3rem 2rem;
  }
`;

export const EventTopSection = styled.div`
  @media (max-width: 441px) {
    & > :nth-child(1) {
      font-size: 21px;
    }
  }
`;

export const EventBottomSection = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  row-gap: 3rem;

  @media (max-width: 1057px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 701px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 505px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 6rem;
  margin-bottom: 4rem;
  gap: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (max-width: 520px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (max-width: 500px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 424px) {
    & .boldText {
      font-size: 20px;
    }
  }
`;

//
export const ShimmerContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;

  @media (max-width: 1320px) {
    & .con {
      width: 260px;
    }
  }

  @media (max-width: 1212px) {
    & .con {
      width: 240px;
    }
  }

  @media (max-width: 1112px) {
    & .con {
      width: 220px;
    }
  }

  @media (max-width: 1024px) {
    & .con {
      width: 200px;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    & .con {
      width: 260px;
    }
  }

  @media (max-width: 884px) {
    & .con {
      width: 240px;
    }
  }

  @media (max-width: 832px) {
    & .con {
      width: 220px;
    }
  }

  @media (max-width: 764px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    & .con {
      width: 260px;
    }
  }

  @media (max-width: 620px) {
    & .con {
      width: 240px;
    }
  }

  @media (max-width: 568px) {
    & .con {
      width: 220px;
    }
  }

  @media (max-width: 520px) {
    & .con {
      width: 220px;
    }
  }

  @media (max-width: 500px) {
    & .con {
      width: 200px;
    }
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    & .con {
      width: 100%;
    }

    & > :nth-child(1) {
      display: none;
    }

    & > :nth-child(2) {
      display: none;
    }
  }
`;
