import React from "react";
import {
  ButtonContainer,
  FirstSectionLeft,
  FirstSectionRight,
  FirstSectionWrapper,
} from "./first_section_style";
import { CommonText } from "../../../../config/text_styles";
import theme from "../../../../theme";
import { Button } from "../../../../config/common/button_style";
import { Link } from "react-router-dom";

type FirstSectionProps = {
  onTap: any;
  onTap2: any;
};

const FirstSection: React.FC<FirstSectionProps> = ({ onTap, onTap2 }) => {
  return (
    <FirstSectionWrapper>
      <FirstSectionLeft>
        <img src="/assets/icons/topdots.svg" alt="img" />
        <CommonText
          fontFamily="GilmerBold"
          color={theme.colors.whiteColor}
          fontSize="56px"
          className="boldText"
        >
          Building Top Tech Talents
        </CommonText>
        <img src="/assets/icons/slash.svg" alt="img" />
        <CommonText
          fontFamily="GilmerRegular"
          color={theme.colors.whiteWithOpacity}
          fontSize="16px"
          lineHeight="25px"
          className="smallText"
        >
          Learn any digital skills of your choice and get access to free
          membership that will transform your journey.
        </CommonText>
        <ButtonContainer>
          <Button
            color={theme.colors.primaryColorDark}
            backgroundColor={theme.colors.whiteColor}
            width="200px"
            height="60px"
            onClick={onTap}
          >
            Get Started Now
          </Button>
          <Link to={"/contact-us"}>
            <Button
              color={theme.colors.secondaryColorLight}
              backgroundColor={theme.colors.primaryColorDark}
              onBackground={"transparent"}
              width="141px"
              height="60px"
            >
              Contact Us
            </Button>
          </Link>
        </ButtonContainer>
      </FirstSectionLeft>
      <FirstSectionRight>
        <img src="/assets/images/girl.png" alt="img" />
        <img src="/assets/images/group1.png" alt="img" />
      </FirstSectionRight>
    </FirstSectionWrapper>
  );
};
export default FirstSection;
